const url = "wss://sedot-gate.lineardev.net/shell";
// const url = "ws://127.0.0.1:8000/shell";

class WS {
    remembered: any
  
    connectWS(): WebSocket {
      if (!this.remembered) {
        this.remembered = new WebSocket(url);
      }
      return this.remembered;
    }

    reconnectWS(): void {
      this.remembered = new WebSocket(url);
    }
}

const Web = new WS();
export const ws = (): WebSocket => Web.connectWS()
ws.reconnect = () => Web.reconnectWS()

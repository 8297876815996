import React, { memo, useCallback, useContext, useEffect, useState } from "react";

import { TerminalContext } from "../../../context/terminalContext";
import { fromBaseString, toBaseString } from "../../../helpers/base64";
import { useAppSelector } from "../../../hooks/redux";
import { wesh } from "../../../utils/react-wesh";

import { ws } from "./SingleWebSocket";

import "./Terminal.css";

declare global {
    interface Window {
        termCommand: string;
        termAwait: boolean;
        termConnected: boolean
        termCommandSpawn: boolean
        onkey: boolean
        latestCommands: string[]
        latestIndex: number
        gateway: string
        alivPrev: number
    }
}

window.termCommand = ""
window.termAwait = false
window.termCommandSpawn = false
window.onkey = false
window.termConnected = false
window.latestIndex = 0

//ps aux --no-headers | awk '{ sum += $3 } END { if (sum > 100) sum = 100; printf("%.0fn", sum) }'
//ps aux --no-headers | awk '{ sum += $4 } END { printf("%.0fn", sum) }'
//df -h / | grep [0-9] | awk '{print $5}' | tr -d "%"

//top -l 1 -s 0 | awk '/CPU usage/ {printf "%.0f\\n", $8}'
//top -l 1 -s 0 | awk '/PhysMem/ {used=$8; free=$10; total=used+free; printf "%d\n", (used/total)*100}'
//iostat -d disk0 | tail -n1 | awk '{print 100 - $NF}' | awk '{printf "%.0f\n", $1}'

//wmic cpu get loadpercentage
// console.log(toBaseString(`iostat -d disk0 | tail -n1 | awk '{print 100 - $NF}' | awk '{printf "%.0f\n", $1}'`))
console.log(fromBaseString(`74=6f=70=20=2d=6c=20=31=20=2d=73=20=30=20=7c=20=61=77=6b=20=27=2f=43=50=55=20=75=73=61=67=65=2f=20=7b=70=72=69=6e=74=66=20=22=25=2e=30=66=5c=6e=22=2c=20=24=38=7d=27`))
const XTerm = memo(() => {
    const { email } = useAppSelector(state => state.userReducer)
    const [terminalMounted, setTerminalMounted] = useState<boolean>(false)
    const [commandOut, setCommandOut] = useState<boolean>(false);
    const [isWritable, setIsWritable] = useState<boolean>(true);

    const { addLog } = useContext(TerminalContext)

    const execCommand = useCallback(async (comm: string) => {
        setCommandOut(true)
        setIsWritable(false)
        switch (comm) {
            case "help":
                wesh.writeRow('HELP Page 1/1')
                wesh.writeRow('This is only emulation for ZSH terminal.')
                wesh.writeRow("For start running command on connected device just run command for example")
                wesh.writeRow('\r\n\x1b[1m  echo "Hello SEDOT"\x1b[0m')
                setCommandOut(false);
                setIsWritable(true);
                break;
            case "":
                window.termAwait = false
                window.termCommandSpawn = false
                break;
            case "clear":
                //TODO: clear terminal
                // term.clear()
                wesh.writeRow("\x1b[1mCleared\x1b[0m")
                window.termAwait = false
                window.termCommandSpawn = false
                break;
            case "^C":
                window.termAwait = false
                window.termCommandSpawn = false
                ws().send("FINL");
                setIsWritable(true);
                setCommandOut(false);
                break;
            default:
                window.termAwait = true
                window.termCommandSpawn = true
                ws().send("STRT")
                console.log(toBaseString(comm), comm)
                ws().send(toBaseString(comm))
            break;
        }
    }, [wesh])

    const checkPrev = (e: MessageEvent) => {
        switch (window.gateway) {
            case "END":
                addLog({
                    id: "Pending...",
                    title: "Command",
                    time: Date.now(),
                    timeAgo: "",
                    timefull: "",
                    command: window.termCommand,
                    output: fromBaseString(e.data)
                })
                if (!window.alivPrev) {
                    wesh.writeBatch(fromBaseString(e.data))
                }
                window.termAwait = false
                window.termCommand = ""
                window.alivPrev = 0
                setCommandOut(false);
                setIsWritable(true);
            break;
            case "ALIV":
                window.termAwait = false
                window.termCommandSpawn = false
                wesh.removeRows(window.alivPrev)
                window.alivPrev = fromBaseString(e.data).split("\n").length
                wesh.writeBatch(fromBaseString(e.data))
            break;
        } 
    }

    const getMessage = (e: MessageEvent) => {
        switch (e.data) {
            case "Connected":
                break
            case "Connection Refused":
                wesh.writeRow("Connection refused...")
                window.termAwait = true
                setIsWritable(false);
                setCommandOut(true);
                break;
            case "END":
            case "ALIV":
                window.gateway = e.data;
                break;
            default:
                checkPrev(e)
                break
        }
        console.log(e)
    }

    const loadTerminal = useCallback(() => {
        if (!email.length) {return}
        console.log("e: " + window.termConnected)
        if (!terminalMounted) {
            setTerminalMounted(true)

            if (!window.termConnected) {
                console.log(email)
                ws().send("Config")
                ws().send(toBaseString(JSON.stringify({user: email, device: window.location.href.split("=")[1]})))
                window.termConnected = true
                window.termCommand = ""
            }
            ws().addEventListener("message", (e) => {getMessage(e)})
            wesh.onExec(execCommand)
        }
    }, [terminalMounted, email, execCommand])

    const check = useCallback(() => {
        if (ws().readyState == 1 && email.length) {
            loadTerminal()
        } else {
            setTimeout(check, 100)
        }
    }, [email, loadTerminal])

    useEffect(() => {
        console.log(email)
        if (email.length) {
            if (ws().readyState == ws().CLOSED) {
                ws.reconnect()
            }
            check()
        }
    }, [email])

    return (
        <wesh.Term
            isLeftData={!commandOut}
            os="windows"
            user={email}
            isWritable={isWritable}
        />
    )
})

const Terminal = memo(() => {
    return (
        <div className="terminal_cont">
            <XTerm />
        </div>
    )
})

export default Terminal
import React, { memo, useCallback,useContext,useMemo,useState } from "react";

import { DevicesActivityContext } from "../../../context/devicesActivityContext";
import { fromBaseString } from "../../../helpers/base64";
import { createModal } from "../../Modals";
import ModalLayout from "../../sectionsFilling/modalLayout/ModalLayout";

import "./AboutCommPopup.css"

type PropsType = {
    active: boolean;
    setActive: any;
}

const AboutCommPopup = memo(({ active, setActive }: PropsType) => {
    const {commandTime, commandTransId, commandUser, userEmail, ethData} = useContext(DevicesActivityContext);

    const command: any = useMemo(() => {
        let data = ethData
        try {
            data = JSON.parse(ethData)
        } catch { /* empty */ }

        return data
    }, [ethData])

    const copyTransId = useCallback(() => {
        navigator.clipboard.writeText(commandTransId).then(() => {
            createModal({ error: false, success: true, message: `Copy transaction id ${commandTransId}` });
        })
    },[commandTransId])

    return (
        <ModalLayout
            active={active}
            setActive={setActive}
            icon={commandUser.length ? "terminal_comm" : "script"}
            sectionName={"About Terminal Command"}
            maximize={true}
            headColor="blue"
        >
            <div className="about_comm_cont">
                <div className="comm_text_row_cont">
                    <div className="comm_text_title">
                        Time:
                    </div>
                    <div className="comm_text_descr">
                        {commandTime}
                    </div>
                </div>
                <div className="comm_text_row_cont trans_cop" onClick={()=> copyTransId()}>
                    <div className="comm_text_title">
                        Transaction ID:
                    </div>
                    <div className="comm_text_descr ">
                        {commandTransId}
                    </div>
                </div>
                <div className="comm_text_row_cont">
                    <div className="comm_text_title">
                        User:
                    </div>
                    <div className="comm_text_descr">
                        {commandUser.length ? userEmail : "System"}
                    </div>
                </div>
                <div className="command_area">
                    <div className="area_title">
                        Command:
                    </div>
                    <textarea readOnly style={{width:"calc(100% - 63px)"}} className="textarea_comm" value={command.command ? fromBaseString(command.command) : command}></textarea>
                </div>
                <div className="outline_area">
                    <div className="area_title">
                        Outline:
                    </div>
                    <textarea readOnly className="textarea_outline" value={command.output ? fromBaseString(command.output) : ""}></textarea>
                </div>
            </div>
        </ModalLayout>
    )
})

export default AboutCommPopup
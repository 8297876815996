import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import Sedock from "sedock";

import { TerminalContext } from "../../../context/terminalContext";
import { timeAgo } from "../../../helpers/time";
import { useAppSelector } from "../../../hooks/redux";

import LogComponent from "./logComponent/LogComponent";

import "./TerminalDevicesLog.css";

const TerminalDevicesLog = memo(() => {
    const deviceId = useMemo(() => location.search.split("=")[1], [])
    const { email } = useAppSelector(state => state.userReducer)
    const { logs, approvedLogs, addApprovedLog } = useContext(TerminalContext)
    const [loaded, setLoaded] = useState(false)
    const [ethBlocks, setEthBlocks] = useState<any[]>([])
    
    const load = useCallback(() => {
        const sedock = new WebSocket("wss://peer-ws.lineardev.net/")

        sedock.addEventListener("message", (e) => {
            if (e.data) {
                const data = JSON.parse(e.data)
                if (data.body.device_id == deviceId) {
                    setEthBlocks([data, ...ethBlocks])
                }
            }
        })

        sedock.addEventListener("open", () => {
            setLoaded(true)
            console.log("Connected to blockchain relay")
            sedock.send("IwantLogin")
            sedock.send("HELO")
        })
    }, [Sedock, ethBlocks, setEthBlocks])
    
    useEffect(() => {
        if (!loaded) {
            load()
        }
    }, [])

    useEffect(() => {
        if (ethBlocks.length) {
            const log = logs.pop()
            if (!log) {return;}
            const block = ethBlocks.pop()
            const tx = block.body

            if (tx.device_id == deviceId && tx.user_id == email) {
                console.log(tx)
                console.log(Math.abs(tx.timestamp - log.time))
                if (Math.abs(tx.timestamp - log.time) <= 1000 ) {
                    console.log("hello")
                    const txId = String(tx.tx)
                    addApprovedLog({
                        id: txId.slice(0, 6) + "..." + txId.slice(txId.length-7, txId.length - 1),
                        title: "Command",
                        time: log.time,
                        timeAgo: "",
                        timefull: "",
                        command: log.command,
                        output: log.output
                    })
                }
            }
            // setEthBlocks(ethBlocks.slice(0, ethBlocks.length - 1))
        }
    }, [ethBlocks])
    // console.log(ethBlocks)
    return (
        <div className="terminal_log_cont">
            <div className="terminal_log_head">
                <img src="/img/popupIcons/file.svg" alt="" />
                <div className="terminal_head_text">
                    Logs
                </div>
            </div>
            <div className="logs-terminal-body">
                {logs.map((entry, i) => {
                const date = new Date(entry.time)
                
                const year = date.getFullYear()
                let month: any = date.getMonth(); if (month.toString().length == 1) {month = "0"+month}
                const day = date.getDate();
                let hours: any = date.getHours(); if (hours.toString().length == 1) {hours = "0"+hours}
                let minutes: any = date.getMinutes(); if (minutes.toString().length == 1) {minutes = "0"+minutes}
                const ago = timeAgo(entry.time)
                return (
                    <LogComponent
                        key={i}
                        id={entry.id}
                        title={entry.title}
                        time={entry.time}
                        timeAgo={ago}
                        timefull={`${year}/${month}/${day} ${hours}:${minutes}`}
                        command={entry.command}
                        output={entry.output}
                    />
                )})}
                {approvedLogs.map((entry, i) => {
                const date = new Date(entry.time)
                
                const year = date.getFullYear()
                let month: any = date.getMonth(); if (month.toString().length == 1) {month = "0"+month}
                const day = date.getDate();
                let hours: any = date.getHours(); if (hours.toString().length == 1) {hours = "0"+hours}
                let minutes: any = date.getMinutes(); if (minutes.toString().length == 1) {minutes = "0"+minutes}
                const ago = timeAgo(entry.time)    
                return (
                    <LogComponent
                        key={i}
                        id={entry.id}
                        title={entry.title}
                        time={entry.time}
                        timeAgo={ago}
                        timefull={`${year}/${month}/${day} ${hours}:${minutes}`}
                        command={entry.command}
                        output={entry.output}
                    />
                )})}
            </div>
        </div>
    )
})

export default TerminalDevicesLog
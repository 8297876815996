import React, { memo, useCallback, useState } from 'react';

import { toBaseString } from '../../../helpers/base64';
import { useAppSelector } from '../../../hooks/redux';
import { makeFetch } from '../../../redux/helpers';
import { CustomSelect } from '../../devicesComponent/addNewDevicePopup/AddNewDevicePopup';
import { createModal } from '../../Modals';
import ModalLayout from '../../sectionsFilling/modalLayout/ModalLayout';

import './AddScriptPopup.css';

type PropsType = {
    active: boolean;
    setActive: any;
    refreshData: any
};

const AddScriptPopup = memo(({ active, setActive, refreshData }: PropsType) => {

    const {lastWisitedOrg} = useAppSelector(state => state.userReducer)

    const [name, setName] = useState<string>('');
    const [desccription, setDescription] = useState<string>('');
    const [selectOs, setSelectOs] = useState<string>('Linux');
    const [command, setCommand] = useState<string>('');

    const addScriptAction = async () => {
        const reqBody = {
            name: name,
            description: desccription,
            system: selectOs,
            execute: toBaseString(command),
            org_id:lastWisitedOrg
        };
        const { success, data } = await makeFetch(
            process.env.REACT_APP_MARKETPLACE_URL + '/marketplace/add',
            'PUT',
            reqBody,
            String(localStorage.getItem('__t'))
        );
        if (success) {
            console.log(data);
            setActive(false);
            createModal({ error: false, success: true, message: 'Script was succesfully added' });
            refreshData()
        } else {
            console.log(data);
            createModal({ error: true, message: data });
        }
    };

    const OsArray = ['Linux', 'Windows', 'macOS', 'Raspberry'];
    return (
        <ModalLayout
            active={active}
            setActive={setActive}
            maximize={false}
            icon={'script'}
            headColor={'blue'}
            sectionName={'Add Script'}>
            <div className="add_script_pop_cont">
                <div className='add_script_areas_overflow'>
                    <div style={{ marginTop: '20px' }} className="invite_new_user_inner">
                        <div className="sect_name_text">Name:</div>
                        <input
                            className="invite_input"
                            type="text"
                            placeholder="Name"
                            value={name}
                            onChange={(e: any) => setName(e.target.value)}
                        />
                    </div>
                    <div className="invite_new_user_inner">
                        <div className="sect_name_text">Description:</div>
                        <input
                            className="invite_input"
                            type="text"
                            placeholder="Name"
                            value={desccription}
                            onChange={(e: any) => setDescription(e.target.value)}
                        />
                    </div>
                    <div style={{ marginTop: '20px' }} className="invite_new_user_inner">
                        <div className="sect_name_text">Select Device OS:</div>
                        <CustomSelect
                            optionsArray={OsArray}
                            value={selectOs}
                            setValue={setSelectOs}
                            emptyOption={false}
                        />
                    </div>
                    <div className="script_outline_area">
                        <div className="script_area_title">Command:</div>
                        <textarea
                            value={command}
                            onChange={(e) => setCommand(e.target.value)}
                            placeholder="Text"
                            className="script_textarea_outline"></textarea>
                    </div>
                </div>
                <div className="add_script_button_popup">
                    <button
                        className="add_script_button"
                        onClick={() => {
                            addScriptAction();
                        }}
                        disabled={!name.length || !desccription.length || !command.length}>
                        Add script
                    </button>
                </div>
            </div>
        </ModalLayout>
    );
});

export default AddScriptPopup;

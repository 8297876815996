import React, { lazy, useCallback, useContext } from 'react';
import { memo, useState } from 'react';

import { DevicesActivityContext } from '../../../context/devicesActivityContext';
import { timeAgo } from '../../../helpers/time';
import { systemTypes } from '../../../redux/reducers/DeviceSlice';

import './DevicesActivity.css';

type PropsType = {
    title?: string;
    setActive: React.Dispatch<React.SetStateAction<boolean>>
    user?: string;
    userEmail: string;
    transId?: string;
    time: number;
    data: string;
    system: systemTypes;
    deviceName?: string;
};

export const TransformString = memo(({inputString}: {inputString?: string}) => {
    if (inputString == undefined) return <></>;
    const firstFiveChars = String(inputString).slice(0, 7);
    const lastFiveChars = String(inputString).slice(-7);

    return <>{`${firstFiveChars}.....${lastFiveChars}`}</>;
});

const SystemImg = memo(({system}: {system: systemTypes}) => {
    switch (system) {
        case 'Windows':
            return <img key={"windows_img"} className="devices_log_img" src="./img/windows.svg" alt="logo" />;
        case 'macOS':
            return <img key={"macos_img"} className="devices_log_img" src="./img/macOS.svg" alt="logo" />;
        case 'Linux':
            return (
                <img
                    key={"linux_img"}
                    className="devices_log_img"
                    style={{ margin: '0 -10px 0 15px' }}
                    src="./img/linux.svg"
                    alt="logo"
                />
            );
        case 'Raspberry':
            return (
                <img
                    key={"raspberry_img"}
                    className="devices_log_img"
                    style={{ width: '32px', height: '37px', margin: '0 0 0 23px' }}
                    src="./img/raspberry.svg"
                    alt="logo"
                />
            );
    }
});

export const resultTime = {
    Element:  memo(({time}: {time: any}) => {
        const date = new Date(time);
        const year = date.getFullYear();
        const monts = date.getMonth() > 10 ? date.getMonth() : `0${date.getMonth()}`;
        const day = date.getDay() > 10 ? date.getDay() : `0${date.getDay()}`;
        const hour = date.getHours() > 10 ? date.getHours() : `0${date.getHours()}`;
        const minutes = date.getMinutes() > 10 ? date.getMinutes() : `0${date.getMinutes()}`;
    
        return <>{`${year}/${monts}/${day}  ${hour}:${minutes}`}</>;
    }),
    Text: (time: any) => {
        const date = new Date(time);
        const year = date.getFullYear();
        const monts = date.getMonth() > 10 ? date.getMonth() : `0${date.getMonth()}`;
        const day = date.getDay() > 10 ? date.getDay() : `0${date.getDay()}`;
        const hour = date.getHours() > 10 ? date.getHours() : `0${date.getHours()}`;
        const minutes = date.getMinutes() > 10 ? date.getMinutes() : `0${date.getMinutes()}`;
    
        return `${year}/${monts}/${day}  ${hour}:${minutes}`;
    }
}

const DevicesActivity = memo(
    ({ title, setActive, user, transId, time, data, system, deviceName, userEmail }: PropsType) => {

        const [logType, setLogType] = useState('devices_min');
        const context = useContext(DevicesActivityContext);

        const logAction = useCallback(() => {
            if (logType === 'devices_min') {
                setLogType('devices_max');
            } else {
                setLogType('devices_min');
            }
        }, [logType]);

        const switchFN = useCallback(() => {
            context.setAbout({
                commandTime: resultTime.Text(time),
                commandTransId: transId,
                commandUser: user ? user : "",
                userEmail: userEmail,
                ethData: data,
            })
            setActive(true);
        }, [])

        return (
            <>
                <div className={'devices_container'}>
                    <div
                        className={logType === 'devices_max' ? 'devices_max' : 'devices_min'}
                        style={{ transition: '0.5s' }}
                        >
                        <div className="devices_info" onClick={() => logAction()}>
                            <div className="first_info_cont">
                                <SystemImg system={system} />
                                <div className="devices_text">
                                    <div className="devices_text_h">{deviceName}</div>
                                    <div className="devices_text_s">{timeAgo(time)}</div>
                                </div>
                            </div>
                            <img className="chevron" src="./img/chevron-down.svg" alt="down" />
                        </div>
                        <div className="devices_fill" onClick={switchFN}>
                            <div className="devices_fill_info">
                                <div className="devices_fill_info_first">Type:</div>
                                <div className="devices_fill_info_second">{userEmail != "Schedular" ? "Terminal Command" : "Script"}</div>
                            </div>
                            <div className="devices_fill_info">
                                <div className="devices_fill_info_first">Time:</div>
                                <div className="devices_fill_info_second">
                                    <resultTime.Element time={time} />
                                </div>
                            </div>
                            <div className="devices_fill_info">
                                <div className="devices_fill_info_first">ID:</div>
                                <div className="devices_fill_info_second">
                                    <TransformString inputString={transId} />
                                </div>
                            </div>
                            <div className="devices_fill_info">
                                <div className="devices_fill_info_first">User:</div>
                                <div className="devices_fill_info_second">{user?.length ? user : "System"}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
);

export default DevicesActivity;
